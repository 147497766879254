import React from 'react'

import Decklist from 'components/decklists/DeckListWithMetaData'
import CardHover from 'components/cards/CardHover'

export const MonoWhiteDeck: React.FC = () => {
  return (
    <Decklist
      name="White 3-0 circa 2014"
      description={
        <>
          This successful deck played several equipment similar {' '}
          to Heirloom Blade, so I imagined what this deck would look {' '}
          like if it was playing Blade instead. Many creatures and tokens{' '}
          can easily fetch another matching type, which made me suspect{' '}
          Heirloom Blade would be strong.
        </>
      }
      maindeck={[
        [
          { name: 'Plains', set: 'ody'},
          { name: 'Plains', set: 'ody'},
          { name: 'Plains', set: 'ody'},
          { name: 'Plains', set: 'ody'},
          { name: 'Plains', set: 'ody'},
          { name: 'Plains', set: 'ody'},
          { name: 'Plains', set: 'ody'},
          { name: 'Plains', set: 'ody'},
          { name: 'Plains', set: 'ody'},
          { name: 'Island', set: 'ody'},
          { name: 'Polluted Delta' },
          { name: 'Hallowed Fountain' },
          { name: 'Godless Shrine' },
          { name: 'Strip Mine' },
          { name: 'Wasteland' },
          { name: 'Mox Emerald' },
          { name: 'Mana Crypt' },
        ],

        [
          { name: 'Isamaru, Hound of Konda' },
          { name: 'Mother of Runes' },
          { name: 'Savannah Lions' },
          { name: 'Steppe Lynx' },
          { name: 'Dryad Militant'},
          { name: 'Path to Exile'},
          { name: 'Swords to Plowshares'}
        ],

        [
          { name: 'Thalia, Guardian of Thraben' },
          { name: 'Phantasmal Image' },
          { name: 'Spellskite' },
          { name: "Umezawa's Jitte" },
          { name: 'Unexpectedly Absent' },
        ],

        [
          { name: 'Lingering Souls' },
          { name: "Council's Judgment" },
          { name: 'Geist of Saint Traft' },
          { name: 'Spectral Procession' },
          { name: 'Mirran Crusader' },
          { name: 'Sword of Fire and Ice' },
          { name: 'Grafted Wargear' },
        ],

        [
          { name: 'Hero of Bladehold' },
          { name: 'Armageddon' },
          { name: 'Reveillark' },
          { name: 'Cloudgoat Ranger'}
        ],
      ]}
    />
  )
}
